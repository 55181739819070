export default function VideoIframe({ src }) {
  return (
    <>
      <iframe
        className="video__iframe"
        width="500"
        height="284"
        src={src}
        title="video-neoway"
      ></iframe>
    </>
  );
}
