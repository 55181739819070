import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

/* const removidos = [
  {
    id: 9,
    categoria: "Tech",
    nome: "Lucas de Paula",
    cargo: "CTO",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/lucasmdepaula/",
    foto: "lucas_de_paula.png",
  },
  {
    id: 1,
    categoria: "Institucional",
    nome: "Carlos Monguilhott",
    cargo: "CEO",
    empresa: "Neoway",
    linkedin:
      "https://www.linkedin.com/search/results/all/?keywords=carlos%20eduardo%20monguilhott%20(kadu)&origin=RICH_QUERY_SUGGESTION&position=0&searchId=9ec4eb9a-62dd-48ff-ba04-28789cceda8d&sid=%3A6a",
    foto: "carlos_monguilhott.png",
  },
  {
    id: 6,
    categoria: "",
    nome: "Fernanda Baggio",
    cargo: "VP of Marketing at Neoway",
    empresa: "B3",
    linkedin: "https://www.linkedin.com/in/fernanda-baggio-28b646/",
    foto: "fernanda_baggio.jpg",
  },
  {
    id: 4,
    categoria: "R&C",
    nome: "Luciana Silveira",
    cargo: "Chief Compliance Officer",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/luciana-silveira-5802bb13/",
    foto: "luciana_silveira.png",
  },
  {
    id: 9,
    categoria: "Go-to-market",
    nome: "Rafael Weigand",
    cargo: "Chief Revenue Officer",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/rafael-weigand/",
    foto: "rafael_weigand.png",
  },
  {
    id: 19,
    categoria: "Data quality",
    nome: "Paula Zanona",
    cargo: "Head of Legal, Privacy and Data Protection & DPO",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/paulazanona/",
    foto: "paula_zanona.png",
  },
]; */

const mentores = [
  {
    id: 0,
    categoria: "Inovação",
    nome: "Rodrigo Barcia",
    cargo: "CEO",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/rodbarcia/",
    foto: "rodrigo_barcia.png",
  },
  {
    id: 1,
    categoria: "Fraude",
    nome: "Rafael Peretti",
    cargo: "Executive Director of Commercial",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/rafaelperetti/",
    foto: "rafael_peretti.png",
  },
  {
    id: 2,
    categoria: "Fraude",
    nome: "Thierry Cadier",
    cargo: "Director of Customer Success",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/thierrycadier/",
    foto: "_0001_Thierry.png",
  },
  {
    id: 3,
    categoria: "Institucional",
    nome: "Marcos Vanderlei",
    cargo: "VP de Infraestrutura de Financiamentos",
    empresa: "B3",
    linkedin:
      "https://www.linkedin.com/in/marcos-vanderlei-167940236/?trk=public_profile_browsemap&originalSubdomain=br",
    foto: "marcos_vanderlei.png",
  },
  {
    id: 4,
    categoria: "Inovação",
    nome: "Pedro Meduna",
    cargo: "Co-founder & Partner",
    empresa: "L4vb",
    linkedin: "https://www.linkedin.com/in/pedromeduna/",
    foto: "pedro_meduna.png",
  },

  {
    id: 5,
    categoria: "Product",
    nome: "Guilherme Mendonça",
    cargo: "VP de Strategic Partnerships ",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/guilherme-mendon%C3%A7a-937b8831/",
    foto: "guilherme_mendonca.png",
  },
  {
    id: 6,
    categoria: "Inovação",
    nome: "Ricardo Raposo",
    cargo: "Data & Analytics ",
    empresa: "B3",
    linkedin: "https://www.linkedin.com/in/ricardo-leite-raposo-434203/",
    foto: "ricardo_raposo.jpg",
  },

  {
    id: 7,
    categoria: "Tech",
    nome: "Matheus Vill",
    cargo: "VP de Platform",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/matheusvill/",
    foto: "matheus_vill.png",
  },
  {
    id: 8,
    categoria: "Tech",
    nome: "Rafael Lessa",
    cargo: "VP de Engineering ",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/rafaelolessa/",
    foto: "rafael_lessa.png",
  },
  {
    id: 9,
    categoria: "Tech",
    nome: "Fabricio Medeiros",
    cargo: "Diretor de Professional Services",
    empresa: "Neoway",
    linkedin:
      "https://www.linkedin.com/in/fabr%C3%ADcio-medeiros-florentino-6983573a/",
    foto: "fabricio_medeiros.png",
  },
  {
    id: 10,
    categoria: "Product",
    nome: "Rodrigo Moreira",
    cargo: "VP de Product ",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/rodrigo-moreira-162a0949/",
    foto: "rodrigo_moreira.png",
  },
  {
    id: 11,
    categoria: "Legal",
    nome: "Guilherme Sardinha",
    cargo: "Head de Produto",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/gmvsardinha/",
    foto: "guilherme_sardinha.png",
  },
  {
    id: 12,
    categoria: "Product",
    nome: "Rodrigo Amancio de Oliveira",
    cargo: "Superintendente de Produtos: Veículos, Imóveis e Consórcios",
    empresa: "B3",
    linkedin: "https://www.linkedin.com/in/rodrigoamancio/",
    foto: "rodrigo_amancio_de_oliveira.png",
  },
  {
    id: 13,
    categoria: "Seguro",
    nome: "Icaro Demarchi Araujo Leite",
    cargo: "Superintendente de Produtos de Seguros",
    empresa: "B3",
    linkedin:
      "https://www.linkedin.com/in/icaro-demarchi-araujo-leite-63711a38/",
    foto: "icaro_demarchi_araujo_leite.png",
  },
  {
    id: 14,
    categoria: "Product",
    nome: "Marcos Caieli",
    cargo: "Diretor de Operações Imobiliárias",
    empresa: "Portal de Documentos",
    linkedin: "https://www.linkedin.com/in/marcos-caielli-6222bb1/",
    foto: "marcos_caieli.png",
  },
  {
    id: 15,
    categoria: "Data quality",
    nome: "Michel Avila",
    cargo: "CDO",
    empresa: "Neoway",
    linkedin: "https://www.linkedin.com/in/avilamichel/",
    foto: "michel_avila.png",
  },
  {
    id: 16,
    categoria: "Ativos Digitais",
    nome: "Jochen Mielke de Lima",
    cargo: "Co-founder Digital Assets",
    empresa: "B3",
    linkedin: "https://www.linkedin.com/in/jochen-mielke-de-lima-72b507/",
    foto: "jochen_mielke_de_lima.png",
  },
];

export default function Carousel() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const sliderRef = React.useRef(null);

  const handlePrev = React.useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = React.useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", (er) => {
      changeSlides(document.documentElement.clientWidth);
    });
  });

  React.useEffect(() => {
    changeSlides(window.screen.availWidth);
  }, []);

  function changeSlides(w) {
    if (w <= 1110) {
      setSlides(4);
    }
    if (w <= 928) {
      setSlides(3);
    }
    if (w <= 752) {
      setSlides(2);
    }
    if (w <= 507) {
      setSlides(2);
    }
    if (w > 1110) {
      setSlides(6);
    }
  }

  const [slides, setSlides] = React.useState(5);

  const renderMentoresSlides = () =>
    mentores.map((mentor, i) => (
      <SwiperSlide key={i}>
        <div className="card-mentor">
          <div className="shadow-layer" />
          <img
            src={require(`../assets/img/mentores/${mentor.foto}`)}
            alt={mentor.nome}
          />

          <div className="cm-flex">
            <div>
              <span className="title">{mentor.nome}</span>
              <div className="desc">
                {mentor.cargo} <br />
                {mentor.empresa}
              </div>
            </div>

            <div
              className="linkedin"
              onClick={() => openInNewTab(mentor.linkedin)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M26.447,26.453H22.893V20.88c0-1.327-.027-3.033-1.853-3.033-1.853,0-2.133,1.447-2.133,2.94v5.667H15.353V15h3.413v1.56h.047a3.732,3.732,0,0,1,3.367-1.847c3.6,0,4.267,2.367,4.267,5.453ZM11.34,13.433a2.085,2.085,0,0,1-1.467-.607,2.065,2.065,0,1,1,1.46.607Zm1.78,13.02H9.553V15H13.12ZM28.227,6H7.773A1.75,1.75,0,0,0,6,7.727V28.273A1.75,1.75,0,0,0,7.773,30H28.22A1.756,1.756,0,0,0,30,28.273V7.727A1.756,1.756,0,0,0,28.22,6Z"
                  transform="translate(-6 -6)"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>
        </div>
      </SwiperSlide>
    ));

  return (
    <div className="ab-c-carousel">
      <Swiper
        ref={sliderRef}
        slidesPerView={slides}
        spaceBetween={16}
        slidesPerGroup={slides}
        loop={false}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
        scrollbar={{ draggable: true }}
      >
        {renderMentoresSlides()}
      </Swiper>

      <div className="prev-arrow" onClick={handlePrev}>
        <svg
          id="Componente_27_1"
          data-name="Componente 27 – 1"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="35.06"
          viewBox="0 0 32 35.06"
        >
          <path
            id="Caminho_34"
            data-name="Caminho 34"
            d="M144.077,32a.674.674,0,0,1-.623-.417L132.724,5.456a3.389,3.389,0,0,0-6.252-.014l-10.735,26.14a.674.674,0,0,1-.623.417H112.4a.337.337,0,0,1-.311-.465L123.373,4.122a6.757,6.757,0,0,1,12.45.013l11.277,27.4a.337.337,0,0,1-.311.465Z"
            transform="translate(0 147.125) rotate(-90)"
            fill="#262fc5"
          />
        </svg>
      </div>

      <div className="next-arrow" onClick={handleNext}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="35.06"
          viewBox="0 0 32 35.06"
        >
          <g
            id="Componente_27_2"
            data-name="Componente 27 – 2"
            transform="translate(32 35.06) rotate(180)"
          >
            <path
              id="Caminho_34"
              data-name="Caminho 34"
              d="M144.077,32a.674.674,0,0,1-.623-.417L132.724,5.456a3.389,3.389,0,0,0-6.252-.014l-10.735,26.14a.674.674,0,0,1-.623.417H112.4a.337.337,0,0,1-.311-.465L123.373,4.122a6.757,6.757,0,0,1,12.45.013l11.277,27.4a.337.337,0,0,1-.311.465Z"
              transform="translate(0 147.125) rotate(-90)"
              fill="#262fc5"
            />
          </g>
        </svg>
      </div>
    </div>
  );
}
