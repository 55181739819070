export default function ButtonS(props) {
    return (
        <div className="container-button-s">
            <button
                {...props}
                className='button-s'
            >
                <div className="layer-bg-s" />
                {props.placeholder}
            </button>
        </div>
    )
}