import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import arrowRight from "../../assets/img/icons/arrow-right.svg";

export default function CardTornar() {
    <Swiper
        pagination={{ clickable: true }}
        modules={[Pagination]}
        className="mySwiper"
        scrollbar={{ draggable: true }}
    >
        <SwiperSlide><Passo1 /></SwiperSlide>
        <SwiperSlide><Passo2 /></SwiperSlide>
        <SwiperSlide><Passo3 /></SwiperSlide>
        <SwiperSlide><Passo4 /></SwiperSlide>
        <SwiperSlide><Passo5 /></SwiperSlide>
    </Swiper>
}

export function Passo1({ className }) {
    return (
        <div className={`ct-module-border-wrap ${className}`}>
            <div className="card-tonar">
                <div className="ct-title">
                    <span>PASSO 1</span>
                </div>
                <div className="ct-descricao">
                    <span>Preencha o formulário de cadastro que você encontra nessa página</span>
                </div>
            </div>
        </div>
    )
}

export function Passo2({ className }) {
    return (
        <div className={`ct-module-border-wrap ${className}`}>
            <div className="icon-arrow">
                <img src={arrowRight} alt="flexa-pra-direita" />
            </div>
            <div className="card-tonar">
                <div className="ct-title">
                    <span>PASSO 2</span>
                </div>
                <div className="ct-descricao">
                    <span>Nossos especialistas vão avaliar o seu modelo de negócio e fazer uma pré-seleção</span>
                </div>
            </div>
        </div>
    )
}

export function Passo3({ className }) {
    return (
        <div className={`ct-module-border-wrap ${className}`}>
            <div className="icon-arrow">
                <img src={arrowRight} alt="flexa-pra-direita" />
            </div>
            <div className="card-tonar">
                <div className="ct-title">
                    <span>PASSO 3</span>
                </div>
                <div className="ct-descricao">
                    <span>Ao receber aprovação, entramos em contato para apresentar todos os detalhes do processo</span>
                </div>
            </div>
        </div>
    )
}

export function Passo4({ className }) {
    return (
        <div className={`ct-module-border-wrap ${className}`}>
            <div className="icon-arrow">
                <img src={arrowRight} alt="flexa-pra-direita" />
            </div>
            <div className="card-tonar">
                <div className="ct-title">
                    <span>PASSO 4</span>
                </div>
                <div className="ct-descricao">
                    <span>Você passará por uma avaliação no pitch day. Esse é o dia D para mostrar todo o potencial do seu negócio!</span>
                </div>
            </div>
        </div>
    )
}

export function Passo5({ className }) {
    return (
        <div className={`ct-module-border-wrap ${className}`}>
            <div className="icon-arrow">
                <img src={arrowRight} alt="flexa-pra-direita" />
            </div>
            <div className="card-tonar">
                <div className="ct-title">
                    <span>PASSO 5</span>
                </div>
                <div className="ct-descricao">
                    <span>Pronto! Já podemos iniciar o projeto de potencialização do seu negócio</span>
                </div>
            </div>
        </div>
    )
}