import Accordion from "./Accordion";

const faqItens = [
  {
    title: "Quais tipos de empresas e startups podem entrar para a Smart?",
    descricao:
      "Startups dos segmentos financeiro, vendas, compliance, fraude, jurídico/legal, varejo e agronegócio (com negócios relacionados a data analytics, inteligência artificial, machine learning etc).",
  },
  {
    title: "O que uma empresa deve ter para ser Smart?",
    descricao:
      "Buscamos startups que apresentem um crescimento rápido e consistente por conta da escalabilidade de seus modelos de negócio: eficiência operacional, relacionamento com o cliente e disrupção.",
  },
  {
    title: "Vou receber investimentos financeiros?",
    descricao:
      "A princípio não haverá nenhum aporte ou empréstimo de recursos financeiros. Porém, ao final do programa, é possível que ocorra um investimento na startup via L4 (fundo B3).",
  },
  {
    title: "Quais recursos a Smart oferece para o meu negócio?",
    content:
      "<span>Há diversos benefícios em participar da Smart para potencializar seu negócio:</span><br /><br /><span>Mentorias com mais de 20 executivos Neoway, B3 e parceiros;</span><br /><span>Crédito para Google Cloud;</span><br /><span>Uso gratuito da Plataforma Neoway com diversos recursos;</span><br /><span>Uso de espaço físico em São Paulo ou Florianópolis;</span><br /><span>Acesso a +700 clientes B2B;</span><br /><span>Programa 100% equity free;</span><br /><span>Acompanhamento e metodologia ACE Cortex;</span><br /><br /><span>Além de todos esses benefícios, a sua startup terá a possibilidade de realizar negócios com B3, Neoway e seus parceiros.</span>,",
  },
  {
    title: "Quanto tempo dura o processo de potencialização?",
    descricao: "O programa durará, em média, 4 meses.",
  },
  {
    title: "É um programa de aceleração?",
    descricao:
      "Não. Smart é uma iniciativa voltada a startups maduras e que tem como principais benefícios a potencialização de produtos, exploração comercial da base de clientes da Neoway e B3, conexão com os profissionais da Neoway e B3, e mentorias feitas por executivos de mercado e mentores da ACE Cortex.",
  },
  {
    title: "Terei que dar equity da empresa?",
    descricao:
      "Não, já que a princípio Neoway e B3 não farão investimentos financeiros na empresa.",
  },
  {
    title: "As mentorias, coworking e exploração comercial são gratuitos?",
    descricao:
      "Sim. O programa de mentorias e exploração comercial da base de clientes é gratuito! O espaço de coworking também não tem custo para o empreendedor (até 5 funcionários por empresa, durante o período do programa).",
  },
  {
    title: "Existe algum contrato de exclusividade com a Neoway e/ou ACE?",
    descricao: "Sim, apenas durante o período do programa.",
  },
  {
    title: "O programa é presencial?",
    descricao:
      "As startups terão um espaço gratuito para seus founders em São Paulo e Florianópolis por até 4 meses. Empresas com mais de 10 funcionários ou que optem por seguir em seus escritórios/cidades podem fazê-lo. As interações para mentorias e eventos de diagnóstico e Demo Day podem ser presenciais.",
  },
];

export default function FAQ() {
  return (
    <>
      {faqItens &&
        faqItens.map((item, index) => {
          return (
            <Accordion
              key={index}
              title={item.title}
              descricao={item.descricao}
            >
              {item.content}
            </Accordion>
          );
        })}
    </>
  );
}
