export default function ButtonG(props) {
  return (
    <div className="container-button-g">
      <button {...props} className="button-g">
        <div className="layer-bg-g" />
        <span>{props.placeholder}</span>
      </button>
    </div>
  );
}
