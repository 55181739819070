import React from "react";

export const CardTypes = () => {
  // function openCardFind(id, sId) {
  //   const cardP = document.getElementById(sId);
  //   const card = document.getElementById(id);
  //   card.classList.toggle("display-none");
  //   cardP.classList.toggle("cards-find-unset");
  // }

  return (
    <section id="cardTypes">
      <div id="ser" className="quem-pode-ser">
        <div className="container-screen">
          <div className="espaçamento-lp-title">
            <span className="lp-title">QUEM PODE SER SMART?</span>
          </div>

          <div>
            <h2 className="title-h2">
              Veja quais tipos de startups estamos em busca
            </h2>
            <div className="descricao">
              <span>Descubra se você é uma delas</span>
            </div>

            <div className="card-container-find">
              <div className="bg-filter" />

              <div className="card--soon">
                <div className="card-content">
                  <p className="title">Em breve mais informações</p>
                </div>
              </div>

              {/* <div className="card-find blue" id='sCr-f' onClick={() => openCardFind('card-find-f', 'sCr-f')}>
            <div className="card-content">
              <span className="title">
                Fraude
              </span>

              <svg xmlns="http://www.w3.org/2000/svg" width="17.53" height="16" viewBox="0 0 17.53 16">
                <path d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z" transform="translate(129.595 16) rotate(180)" fill="#fff" />
                <path d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z" transform="translate(129.595 16) rotate(180)" fill="#fff" />
              </svg>

              <div id='card-find-f' className="card-description">
                <div>
                  <span>Buscamos startups que desenvolvem soluções para:</span>
                </div>

                <ul>
                  <li><span className="dot">• </span><strong>Fraude Pix: </strong>identificar possíveis fraudes na utilização de pagamentos via Pix</li>
                  <li><span className="dot">• </span><strong>Fingerprint Digital: </strong>fraudes em máquinas digitais e dados em relação a padrões de fraude no mundo digital</li>
                </ul>
              </div>
            </div>
          </div> */}

              {/*<div
                className="card-find pink"
                id="sCr-rc"
                onClick={() => openCardFind("card-find-rc", "sCr-rc")}
              >
                <div className="card-content">
                  <span className="title">Mercado de capitais</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.53"
                    height="16"
                    viewBox="0 0 17.53 16"
                  >
                    <path
                      d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z"
                      transform="translate(129.595 16) rotate(180)"
                      fill="#fff"
                    />
                    <path
                      d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z"
                      transform="translate(129.595 16) rotate(180)"
                      fill="#fff"
                    />
                  </svg>

                  <div id="card-find-rc" className="card-description">
                    <div>
                      <span>
                        Buscamos negócios que desenvolvem soluções para:
                      </span>
                    </div>

                    <ul>
                      <li>
                        <span className="dot">• </span>
                        <strong>Análise de sentimentos: </strong>monitoramento
                        de publicações, discussões e tendências do mercado de
                        capitais em redes sociais e mídias digitais.
                      </li>
                      <li>
                        <span className="dot">• </span>
                        <strong>Análise de comportamentos: </strong>solução de
                        NPL para apoiar tomadas de decisão de operadores
                        financeiros.
                      </li>
                      <li>
                        <span className="dot">• </span>
                        <strong>
                          Ganhos operacionais para operadores financeiros:{" "}
                        </strong>
                        tecnologia para criação e gestão de carteiras de
                        investimento personalizadas.
                      </li>
                      <li>
                        <span className="dot">• </span>
                        <strong>ESG: </strong>captação de dados sobre
                        iniciativas ambientais, sociais e de governança, de
                        empresas públicas e privadas para análise e definição de
                        Score ESG.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="card-find orange"
                id="sCr-sm"
                onClick={() => openCardFind("card-find-sm", "sCr-sm")}
              >
                <div className="card-content">
                  <span className="title">Seguro, Cobrança e Crédito</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.53"
                    height="16"
                    viewBox="0 0 17.53 16"
                  >
                    <path
                      d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z"
                      transform="translate(129.595 16) rotate(180)"
                      fill="#fff"
                    />
                    <path
                      d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z"
                      transform="translate(129.595 16) rotate(180)"
                      fill="#fff"
                    />
                  </svg>

                  <div id="card-find-sm" className="card-description">
                    <div>
                      <span>
                        Buscamos negócios que desenvolvem soluções para:
                      </span>
                    </div>

                    <ul>
                      <li>
                        <span className="dot">• </span>
                        <strong>Dados georreferenciados:</strong> tecnologias
                        que viabilizam a segmentação de perfis comportamentais,
                        sociodemográficos e econômicos.
                      </li>
                      <li>
                        <span className="dot">• </span>
                        <strong>Dados sintéticos:</strong> tecnologias que
                        possam ser replicadas para utilização nos segmentos de
                        seguro, cobrança e crédito
                      </li>
                      <li>
                        <span className="dot">• </span>
                        <strong>Machine learning e Deep learning:</strong>{" "}
                        tecnologias que possam ser replicadas para utilização
                        nos segmentos de seguro, cobrança e crédito.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>*/}

              {/* <div className="card-find darkblue" id='sCr-a' onClick={() => openCardFind('card-find-a', 'sCr-a')}>
            <div className="card-content">
              <span className="title">
                Automotivo
              </span>

              <svg xmlns="http://www.w3.org/2000/svg" width="17.53" height="16" viewBox="0 0 17.53 16">
                <path d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z" transform="translate(129.595 16) rotate(180)" fill="#fff" />
                <path d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z" transform="translate(129.595 16) rotate(180)" fill="#fff" />
              </svg>

              <div id='card-find-a' className="card-description">
                <div><span>Buscamos startups que desenvolvem soluções para:</span></div>

                <ul>
                  <li><span className="dot">• </span><strong>Dados do ecossistema de veículos: </strong>Leilão, Recall, etc.</li>
                  <li><span className="dot">• </span><strong>Análise de imagem das condições e características dos veículos</strong></li>
                  <li><span className="dot">• </span><strong>Transformação digital da jornada de comercialização de veículos</strong></li>
                </ul>
              </div>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
