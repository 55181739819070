import React from "react";

export default class ClassButtonG extends React.Component {
    render() {
      return (
        <div className="container-button-g">
          <button {...this.props} className="button-g">
            <div className="layer-bg-g" />
            <span>{this.props.placeholder}</span>
          </button>
        </div>
      );
    }
  }