import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Formulario, LandingPage, Sucesso } from "./views";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/sucesso" element={<Sucesso />} />
        <Route path="/smart-contato" element={<Formulario />} />
      </Routes>
    </BrowserRouter>
  );
}
