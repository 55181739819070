import React from "react";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../components";

export default function Sucesso() {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate("/");
  }, [navigate]);

  return (
    <>
      <NavBar />
      <div className="sucesso">
        <span className="title-form">
          Seus dados foram enviados com sucesso.
        </span>
      </div>
    </>
  );
}
