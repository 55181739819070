export function Incubadora({ className }) {
  return (
    <div className={`incubadora ${className}`}>
      <div className="title-table">
        <span>INCUBADORA</span>
      </div>
      
      <li className="item tempo">
        <div><span className="title">TEMPO DE APOIO</span></div>
        <div><span className="i-descricao">1 a 3 anos</span></div>
      </li>
      
      <li className="item modelo">
        <div>
          <span className="title">MODELO DE NEGÓCIOS</span> </div>
        <div>
          <span className="i-descricao">Sem fins lucrativo, mantido por outras instituições</span>
        </div>
      </li>
      
      <li className="item oferta">
        <div>
          <span className="title">OFERTA DE SERVIÇOS</span> </div>
        <div>
          <span className="i-descricao">Focada em infraestrutura e espaço físico</span>
        </div>
      </li>
      
      <li className="item porte">
        <div>
          <span className="title">PORTE DAS EMPRESAS</span> </div>
        <div>
          <span className="i-descricao">Pequenas empresas</span>
        </div>
      </li>
      
      <li className="item capita">
        <div>
          <span className="title">CAPITAL / FINANCIAMENTO</span> </div>
        <div>
          <span className="i-descricao">Não conta com investimentos</span>
        </div>
      </li>
      
      <li className="item contrapartidas">
        <div>
          <span className="title">CONTRAPARTIDAS</span> </div>
        <div>
          <span className="i-descricao">Pagamento de taxas</span>
        </div>
      </li>
      
      <li className="item processo">
        <div>
          <span className="title">PROCESSO SELETIVO</span> </div>
        <div>
          <span className="i-descricao">Baixa concorrência</span>
        </div>
      </li>
      
      <li className="item acompanhamento">
        <div>
          <span className="title">ACOMPANHAMENTO</span> </div>
        <div>
          <span className="i-descricao">Equipe restrita</span>
        </div>
      </li>
      
      <li className="item plataforma">
        <div>
          <span className="title">PLATAFORMA NEOWAY</span> </div>
        <div>
          <span className="i-descricao">Não</span>
        </div>
      </li>
      
      <li className="item criacao">
        <div>
          <span className="title">CRIAÇÃO DE OFERTAS E NEGÓCIOS JUNTO COM EMPRESA</span> </div>
        <div>
          <span className="i-descricao">Normalmente, não existe essa possibilidade</span>
        </div>
      </li>
    </div>
  )
}

export function Potencializadora({ className }) {
  return (
    <div className={`potencializadora-smart ${className}`}>
      <div className="title-table">
        <span>
          POTENCIALIZADORA <br />SMART
        </span>
      </div>
      
      <li className="item tempo">
        <div>
          <span className="title">TEMPO DE APOIO</span> </div>
        <div>
          <span className="i-descricao">4 meses (primeiro ciclo)</span>
        </div>
      </li>
      
      <li className="item modelo">
        <div>
          <span className="title">MODELO DE NEGÓCIOS</span> </div>
        <div>
          <span className="i-descricao">Com fins lucrativos, mantida pela B3 e Neoway, a fim de gerar investimentos, parcerias e negócios.</span>
        </div>
      </li>
      
      <li className="item oferta">
        <div>
          <span className="title">OFERTA DE SERVIÇOS</span> </div>
        <div>
          <span className="i-descricao">Focada na gestão do negócio, mentorias, networking, infraestrutura e espaço físico</span>
        </div>
      </li>
      
      <li className="item porte">
        <div>
          <span className="title">PORTE DAS EMPRESAS</span> </div>
        <div>
          <span className="i-descricao">Startups escaláveis</span>
        </div>
      </li>
      
      <li className="item capita">
        <div>
          <span className="title">CAPITAL / FINANCIAMENTO</span> </div>
        <div>
          <span className="i-descricao">Não conta com investimento, havendo possibilidade futura</span>
        </div>
      </li>
      
      <li className="item contrapartidas">
        <div>
          <span className="title">CONTRAPARTIDAS</span> </div>
        <div>
          <span className="i-descricao">Sem contrapartidas</span>
        </div>
      </li>
      
      <li className="item processo">
        <div>
          <span className="title">PROCESSO SELETIVO</span> </div>
        <div>
          <span className="i-descricao">Alta concorrência</span>
        </div>
      </li>
      
      <li className="item acompanhamento">
        <div>
          <span className="title">ACOMPANHAMENTO</span> </div>
        <div>
          <span className="i-descricao">Equipe exclusiva</span>
        </div>
      </li>
      
      <li className="item plataforma">
        <div>
          <span className="title">PLATAFORMA NEOWAY</span> </div>
        <div>
          <span className="i-descricao">Sim</span>
        </div>
      </li>
      
      <li className="item criacao">
        <div>
          <span className="title">CRIAÇÃO DE OFERTAS E NEGÓCIOS JUNTO COM EMPRESA</span> </div>
        <div>
          <span className="i-descricao">Existe a possibilidade e é um dos objetivos principais do programa</span>
        </div>
      </li>
    </div>
  )
}

export function Aceleradora({ className }) {
  return (
    <div className={`aceleradora ${className}`}>
      <div className="title-table">
        <span>
          ACELERADORA
        </span>
      </div>
      
      <li className="item tempo">
        <div>
          <span className="title">TEMPO DE APOIO</span> </div>
        <div>
          <span className="i-descricao">4 a 6 meses</span>
        </div>
      </li>
      
      <li className="item modelo">
        <div>
          <span className="title">MODELO DE NEGÓCIOS</span> </div>
        <div>
          <span className="i-descricao">Com fins lucrativos, investidores privados que monetizam através da venda das ações das startups apoiadas</span>
        </div>
      </li>
      
      <li className="item oferta">
        <div>
          <span className="title">OFERTA DE SERVIÇOS</span> </div>
        <div>
          <span className="i-descricao">Focada na gestão do negócio, mentorias, networking</span>
        </div>
      </li>
      
      <li className="item porte">
        <div>
          <span className="title">PORTE DAS EMPRESAS</span> </div>
        <div>
          <span className="i-descricao">Startups escaláveis</span>
        </div>
      </li>
      
      <li className="item capita">
        <div>
          <span className="title">CAPITAL / FINANCIAMENTO</span> </div>
        <div>
          <span className="i-descricao">Normalmente, investe capital inicial no negócio (tickets relativamente baixos)</span>
        </div>
      </li>
      
      <li className="item contrapartidas">
        <div>
          <span className="title">CONTRAPARTIDAS</span> </div>
        <div>
          <span className="i-descricao">Cessão de percentual da participação acionária</span>
        </div>
      </li>
      
      <li className="item processo">
        <div>
          <span className="title">PROCESSO SELETIVO</span> </div>
        <div>
          <span className="i-descricao">Alta concorrência</span>
        </div>
      </li>
      
      <li className="item acompanhamento">
        <div>
          <span className="title">ACOMPANHAMENTO</span> </div>
        <div>
          <span className="i-descricao">Equipe dedicada</span>
        </div>
      </li>
      
      <li className="item plataforma">
        <div>
          <span className="title">PLATAFORMA NEOWAY</span> </div>
        <div>
          <span className="i-descricao">Não</span>
        </div>
      </li>
      
      <li className="item criacao">
        <div>
          <span className="title">CRIAÇÃO DE OFERTAS E NEGÓCIOS JUNTO COM EMPRESA</span> </div>
        <div>
          <span className="i-descricao">Existe a possibilidade</span>
        </div>
      </li>
    </div>
  )
}