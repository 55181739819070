import React from "react"

export default function Accordion(props) {

    const [open, setOpen] = React.useState(false)

    return (
        <div
            className={`${open ? 'open' : ''}`}
            onClick={() => setOpen(!open)}
            style={{ marginBottom: '1rem' }}
        >
            <div className='accordion'>
                <div className="acc-t-container">
                    <span>{props.title}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="plus-w">
                        <g id="Grupo_149" data-name="Grupo 149" transform="translate(-948 -24)">
                            <circle id="Elipse_29" data-name="Elipse 29" cx="12" cy="12" r="12" transform="translate(948 24)" fill="#fff" />
                            <path id="Line_9" data-name="Line 9" d="M8,9a1,1,0,0,1-.707-.293l-8-8a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l8,8A1,1,0,0,1,8,9Z" transform="translate(960.071 30.414) rotate(45)" fill="#121125" />
                            <path id="Line_10" data-name="Line 10" d="M0,9a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414l8-8a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-8,8A1,1,0,0,1,0,9Z" transform="translate(960.071 30.414) rotate(45)" fill="#121125" />
                        </g>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="plus-b">
                        <g id="Grupo_150" data-name="Grupo 150" transform="translate(-948 -24)">
                            <circle id="Elipse_30" data-name="Elipse 30" cx="12" cy="12" r="12" transform="translate(948 24)" fill="#3acff7" />
                            <circle id="Elipse_29" data-name="Elipse 29" cx="12" cy="12" r="12" transform="translate(948 24)" fill="#3acff7" />
                            <path id="Line_9" data-name="Line 9" d="M8,9a1,1,0,0,1-.707-.293l-8-8a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l8,8A1,1,0,0,1,8,9Z" transform="translate(960.071 30.414) rotate(45)" fill="#121125" />
                            <path id="Line_10" data-name="Line 10" d="M0,9a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414l8-8a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-8,8A1,1,0,0,1,0,9Z" transform="translate(960.071 30.414) rotate(45)" fill="#121125" />
                        </g>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="times">
                        <g id="Grupo_151" data-name="Grupo 151" transform="translate(-948 -24)">
                            <circle id="Elipse_31" data-name="Elipse 31" cx="12" cy="12" r="12" transform="translate(948 24)" fill="#fa510a" />
                            <circle id="Elipse_32" data-name="Elipse 32" cx="12" cy="12" r="12" transform="translate(948 24)" fill="#fa510a" />
                            <path id="Line_9" data-name="Line 9" d="M8,9a1,1,0,0,1-.707-.293l-8-8a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l8,8A1,1,0,0,1,8,9Z" transform="translate(956.071 32.071)" fill="#fff" />
                            <path id="Line_10" data-name="Line 10" d="M0,9a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414l8-8a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-8,8A1,1,0,0,1,0,9Z" transform="translate(956.071 32.071)" fill="#fff" />
                        </g>
                    </svg>
                </div>
            </div>
            <div className="acc-descricao" >
                {props.children ? <p  dangerouslySetInnerHTML={{__html: props.children}}/> : <span>{props.descricao}</span>}
            </div>
        </div>
    )
}