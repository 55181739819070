import React, { useRef, useState } from "react";
import { NavBar } from "../components";
import ClassButtonG from "../components/ClassButtonG";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
// const pass = {
//   word: "neoform",
// };

export default function Formulario() {
  // const [password, setPassword] = React.useState("");
  // const [show, setShow] = React.useState(false);

  // React.useEffect(() => {
  //   const local = localStorage.getItem("pw2");
  //   if (local) setShow(true);
  // }, []);

  // function handlePass() {
  //   if (password === pass.word) {
  //     localStorage.setItem("pw2", "s");
  //     setShow(true);
  //   }
  // }

  const navigate = useNavigate();
  React.useEffect(() => {
    navigate("/");
  }, [navigate]);

  window.ref = ``;

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  function getUtmsFromUrl() {
    const list = {};
    let tracks = window.location.search.substr(1).split("&");
    // debugger;

    // get from url
    for (let i = 0; i < tracks.length; i++) {
      let item = tracks[i].split("=");
      if (item[0]) {
        list[item[0]] = item[1];
      }
    }

    // if empty, get from cookies
    if (list && !list.length) {
      // debugger;
      const cookieKeys = [
        "gh_utm_campaign",
        "gh_utm_content",
        "gh_utm_medium",
        "gh_utm_term",
        "gh_utm_source",
      ];
      for (let cookieKey of cookieKeys) {
        let cookieVal = getCookie(cookieKey);
        if (cookieVal) {
          list[cookieKey] = cookieVal;
        }
      }
    }

    return list;
  }

  function initGhUtm(verbose, fieldSelector) {
    // debugger;
    if (fieldSelector) {
      let fields = document.querySelectorAll(fieldSelector);
      let cookie_jar = getUtmsFromUrl();
      for (let field of fields) {
        let field_name = "gh_" + field.getAttribute("name");
        if (field_name && field_name.indexOf("utm") > -1) {
          // field.parentNode.style.display = "none";
          if (cookie_jar[field_name]) {
            field.value = cookie_jar[field_name];
          }
          if (verbose) {
            console.log("UTM field: " + field_name);
          }
        } else {
          if (verbose) {
            console.log("Other field: " + field_name);
          }
        }
      }
    }
  }

  function setUtmCookies() {
    let cookie_jar = getUtmsFromUrl();
    if (cookie_jar && Object.keys(cookie_jar).length > 0) {
      let keys = Object.keys(cookie_jar);
      for (let key of keys) {
        let cookie_val = cookie_jar[key];
        document.cookie = "gh_" + key + "=" + cookie_val + ";path=/";
      }
    }
  }

  React.useEffect(() => {
    setUtmCookies();

    window.addEventListener("load", (event) => {
      initGhUtm(false, "#conversion-form input");

      let iconBr = document.createElement("img");
      iconBr.className = "flag";
      iconBr.setAttribute("width", 26);
      iconBr.setAttribute(
        "src",
        "https://dk9suync0k2va.cloudfront.net/js/rd/stable/flags/4x3/br.svg"
      );
      //document.querySelector("#select2-chosen-2").appendChild(iconBr);
    });

    const myForm = document.querySelector("#conversion-form");
    if (myForm) myForm.addEventListener("submit", clickBtn);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function clickBtn() {
    let btnForm = document.querySelector("#submit");
    btnForm.disabled = true;
  }

  function enviandoCheck() {
    // const respostaPublicidade = document.querySelector("#check_publicidade");
    const respostaConteudo = document.querySelector("#check_conteudo");

    // if (respostaPublicidade.checked) {
    //   document.querySelector("[name='recebe_publicidade']").value = "Sim";
    // } else {
    //   document.querySelector("[name='recebe_publicidade']").value = "Não";
    // }

    if (respostaConteudo.checked) {
      document.querySelector("[name='recebe_conteudo']").value = "Sim";
    } else {
      document.querySelector("[name='recebe_conteudo']").value = "Não";
    }
  }

  // máscara telefone
  const [phone, setPhone] = useState("");

  const formatPhone = (event) => {
    let input = event.target;
    setPhone(phoneMask(input.value));
  };

  const phoneMask = (value) => {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value;
  };

  // if (!show) {
  //   return (
  //     <div
  //       style={{
  //         width: "100%",
  //         height: "100vh",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <form onSubmit={handlePass}>
  //         <input
  //           type="text"
  //           value={password}
  //           onChange={({ target }) => setPassword(target.value)}
  //         />
  //         <button>Enviar</button>
  //       </form>
  //     </div>
  //   );
  // }

  // Desabilita botão de enviar form e deixa cursor do mouse carregando
  let btnRef = useRef();

  const onBtnClick = (e) => {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
  };

  return (
    <>
      <Helmet>
        <title>Cadastre sua Startup | Seja Smart</title>
        <meta
          name="description"
          content="Responda o formulário de cadastro da sua startup! Em seguida, vamos ajudar você a potencializar seu negócio."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <NavBar other={true} />

      <div className="smart-form animeShow">
        <img
          className="bg-m"
          alt="ondas"
          src={require("../assets/img/bg/bg-m.png")}
        />

        <div className="container-form">
          <div className="info">
            <h1 className="title-form">
              Crescimento rápido?
              <br /> Seja <strong className="smart">Smart</strong>
            </h1>
            <br />
            <h2 className="descricao">
              Conta pra gente como foi a jornada da sua startup até aqui. Em
              seguida, vamos ajudar você a potencializar o seu processo de
              inovação e crescimento no mercado.
            </h2>
          </div>

          <form
            id="conversion-form"
            action="https://cloud.conteudo.neoway.com.br/HandlerSmart"
            method="POST"
            className="form"
          >
            <input type="hidden" name="utm_source" />
            <input type="hidden" name="utm_medium" />
            <input type="hidden" name="utm_campaign" />
            <input type="hidden" name="utm_term" />
            <input type="hidden" name="utm_content" />
            <div className="double">
              <label htmlFor="nome">
                Nome
                <input name="nome" id="nome" required />
              </label>
              <label htmlFor="sobrenome">
                Sobrenome
                <input name="sobrenome" id="sobrenome" required />
              </label>
            </div>
            <div className="double">
              <label htmlFor="email">
                Email
                <input name="email" id="email" type="email" required />
              </label>

              <label htmlFor="linkedin">
                LinkedIn da Startup
                <input name="linkedin" id="linkedin" required />
              </label>
            </div>
            <div className="double">
              <label htmlFor="empresa">
                Nome da Startup
                <input name="empresa" id="empresa" required />
              </label>
              <label htmlFor="site">
                Site
                <input name="site" id="site" required />
              </label>
            </div>

            <div className="one">
              <label htmlFor="telefone">
                Telefone
                <input
                  type="tel"
                  id="telefone"
                  name="telefone"
                  onChange={formatPhone}
                  value={phone}
                  minLength="14"
                  maxLength="15"
                  pattern="(\([0-9]{2}\))\s([9]{1})?([0-9]{4})-([0-9]{4})"
                  title="Preencha esse campo com o número no formato correto."
                  required
                />
              </label>
            </div>

            <div className="double">
              <label htmlFor="vertical">
                Vertical
                <select required name="vertical" id="vertical">
                  <option value="">Vertical</option>
                  <option value="AGRONEGOCIO">Agronegócio</option>
                  <option value="AUTOMOTIVO">Automotivo</option>
                  <option value="BENEFICIOS">Benefícios</option>
                  <option value="BENS DE CONSUMO">Bens de Consumo</option>
                  <option value="CONSTRUCAO CIVIL">Construção Civil</option>
                  <option value="EDUCACAO">Educação</option>
                  <option value="ESCRITORIO DE ADVOCACIA">
                    Escritório de Advocacia
                  </option>
                  <option value="FARMACEUTICA">Farmacêutica</option>
                  <option value="INSTITUICOES FINANCEIRAS">
                    Instituições Financeiras
                  </option>
                  <option value="MANUFATURA">Manufatura</option>
                  <option value="OLEO &amp; GAS">Óleo &amp; Gás</option>
                  <option value="PRESTADORES DE SERVICOS">
                    Prestadores de Serviços
                  </option>
                  <option value="SAUDE">Saúde</option>
                  <option value="SEGUROS">Seguros</option>
                  <option value="SETOR PUBLICO">Setor Público</option>
                  <option value="TECNOLOGIA">Tecnologia</option>
                  <option value="TELECOM E MIDIA">Telecom e Mídia</option>
                  <option value="UTILIDADES">Utilidades</option>
                  <option value="VAREJO E DISTRIBUICAO">
                    Varejo e Distribuição
                  </option>
                  <option value="VIAGEM &amp; TRANSPORTE">
                    Viagem &amp; Transporte
                  </option>
                  <option value="OUTROS">Outros</option>
                </select>
              </label>
            </div>

            <div className="double">
              <label htmlFor="datafundacao">
                Data de fundação
                <div className="double">
                  <select name="datafundacao" id="datafundacao" required>
                    <option value="">Ano de Fundação</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                    <option value="2018">2018</option>
                    <option value="2017">2017</option>
                    <option value="2016">2016</option>
                    <option value="2015">2015</option>
                    <option value="2014">2014</option>
                    <option value="2013">2013</option>
                    <option value="2012">2012</option>
                    <option value="2011">2011</option>
                    <option value="2010">2010</option>
                    <option value="2009">2009</option>
                    <option value="2008">2008</option>
                    <option value="2007">2007</option>
                    <option value="2006">2006</option>
                    <option value="2005">2005</option>
                    <option value="2004">2004</option>
                    <option value="2003">2003</option>
                    <option value="2002">2002</option>
                    <option value="2001">2001</option>
                    <option value="2000">2000</option>
                    <option value="1999">1999</option>
                    <option value="1998">1998</option>
                    <option value="1997">1997</option>
                    <option value="1996">1996</option>
                    <option value="1995">1995</option>
                    <option value="1994">1994</option>
                    <option value="1993">1993</option>
                    <option value="1992">1992</option>
                    <option value="1991">1991</option>
                    <option value="1990">1990</option>
                  </select>
                </div>
              </label>

              <label htmlFor="uf">
                Local
                <select required name="uf" id="uf">
                  <option value="">Local</option>
                  <option value="AC">AC</option>
                  <option value="AL">AL</option>
                  <option value="AP">AP</option>
                  <option value="AM">AM</option>
                  <option value="BA">BA</option>
                  <option value="CE">CE</option>
                  <option value="DF">DF</option>
                  <option value="ES">ES</option>
                  <option value="GO">GO</option>
                  <option value="MA">MA</option>
                  <option value="MT">MT</option>
                  <option value="MS">MS</option>
                  <option value="MG">MG</option>
                  <option value="PA">PA</option>
                  <option value="PB">PB</option>
                  <option value="PR">PR</option>
                  <option value="PE">PE</option>
                  <option value="PI">PI</option>
                  <option value="RJ">RJ</option>
                  <option value="RN">RN</option>
                  <option value="RS">RS</option>
                  <option value="RO">RO</option>
                  <option value="RR">RR</option>
                  <option value="SC">SC</option>
                  <option value="SP">SP</option>
                  <option value="SE">SE</option>
                  <option value="TO">TO</option>
                </select>
              </label>
            </div>

            <div className="double">
              <label htmlFor="modelonegocio">
                Modelo de Negócio
                <div className="double">
                  <select name="modelonegocio" id="modelonegocio" required>
                    <option value="">Modelo de Negócio</option>
                    <option value="B2B">B2B (Business to Business)</option>
                    <option value="B2C">B2C (Business to Consumer)</option>
                    <option value="B2E">B2E (Business to Employee)</option>
                    <option value="B2G">B2G (Business to Government)</option>
                    <option value="B2B2C">
                      B2B2C (Business to Business to Consumer)
                    </option>
                  </select>
                </div>
              </label>

              <label htmlFor="modeloreceita">
                Modelo de Receita
                <select name="modeloreceita" id="modeloreceita" required>
                  <option value="">Modelo de Receita</option>
                  <option value="SaaS">SaaS</option>
                  <option value="Marketplace">Marketplace</option>
                  <option value="Consumer">Consumer</option>
                  <option value="E-commerce">E-commerce</option>
                  <option value="API">API</option>
                  <option value="Licenciamento">Licenciamento</option>
                  <option value="Hardware">Hardware</option>
                  <option value="Consultoria">Consultoria</option>
                  <option value="Software House">Software House</option>
                  <option value="Outros">Outros</option>
                </select>
              </label>
            </div>

            <div className="double">
              <label htmlFor="funcionarios">
                Funcionários
                <select required name="funcionarios" id="funcionarios">
                  <option value="">Funcionários</option>
                  <option value="1 - 20">1 - 20</option>
                  <option value="21 - 50">21 - 50</option>
                  <option value="51 - 100">51 - 100</option>
                  <option value="101 - 500">101 - 500</option>
                  <option value="501 - 1000">501 - 1000</option>
                  <option value="1000+">1000+</option>
                </select>
              </label>
            </div>

            {/* <div className="one">
              <label htmlFor="clientes_ativos">
                Principais Clientes
                <input name="clientes_ativos" id="clientes_ativos" required />
              </label>
            </div> */}

            <div className="one">
              <label htmlFor="faturamento">
                Faturamento (Faturamento 2022 e previsão para 2023)
                <input name="faturamento" id="faturamento" required />
              </label>
            </div>

            <div className="double">
              <label htmlFor="como_conheceu">
                Como conheceu a Smart?
                <select name="como_conheceu" id="como_conheceu" required>
                  <option value="">Como conheceu a Smart?</option>
                  <option value="Data Driven Business 22">
                    Data Driven Business 22
                  </option>
                  <option value="ACE">ACE</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Notícias">Notícias</option>
                  <option value="Amigos ou colegas">Amigos ou colegas</option>
                  <option value="Outros">Outros</option>
                </select>
              </label>
            </div>

            <div className="double">
              <label htmlFor="pergunta_corte">
                Já participou ou está participando atualmente de algum outro
                processo de aceleração/potencialização ou incubação?
                <select name="pergunta_corte" id="pergunta_corte" required>
                  <option value="">Selecione a opção</option>
                  <option value="Sim">Sim</option>
                  <option value="Não">Não</option>
                </select>
              </label>
            </div>

            {/* <div className="double">
              <label htmlFor="modelonegocio">
                Descreva seu pitch de vendas
                <input
                  type="text"
                  id="modelonegocio"
                  name="modelonegocio"
                  maxLength="254"
                  required=""
                />
              </label>
            </div> */}

            <div className="one">
              <label className="sua_demanda">
                Descrição (Overview da startup e do produto)
                <textarea
                  name="sua_demanda"
                  id="sua_demanda"
                  required
                  maxLength="254"
                ></textarea>
              </label>
            </div>

            <div className="termos">
              <br />
              <label className="label-checkbox">
                <span className="text-bigger">
                  <strong>
                    Enviar pitch para o email: thais.mantuani@goace.vc.
                  </strong>
                </span>
              </label>

              <br />

              <label className="label-checkbox">
                <span>
                  Para obter mais informações sobre como tratamos os seus dados
                  pessoais, consulte a nossa{" "}
                  <a
                    href="https://f.hubspotusercontent10.net/hubfs/7323764/POL%C3%8DTICA%20DE%20TRATAMENTO%20DE%20DADOS.docx.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Política de Tratamento de Dados
                  </a>{" "}
                  e{" "}
                  <a
                    href="https://blog.neoway.com.br/politica-de-privacidade/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aviso de Privacidade
                  </a>{" "}
                  do Site Neoway.
                </span>
              </label>

              <label className="label-checkbox">
                <input
                  id="check_conteudo"
                  name="check_conteudo"
                  type="checkbox"
                  value="1"
                  onClick={enviandoCheck}
                />
                <input
                  type="hidden"
                  id="recebe_conteudo"
                  name="recebe_conteudo"
                  value="Sim"
                />
                Quero receber informações e materiais adicionais da Neoway no
                e-mail informado.
              </label>
            </div>

            <input
              type="hidden"
              id="paginaSucesso"
              name="paginaSucesso"
              value="https://smartpotencializadora.com.br/sucesso"
            />
            <input type="hidden" id="slug" name="slug" value="smart-contato" />
            <ClassButtonG
              ref={btnRef}
              onClick={onBtnClick}
              placeholder="QUERO SER SMART!"
              id="submit"
              type="submit"
            />
          </form>
        </div>
      </div>

      <footer className="footer">
        <div className="container-footer">
          <img src={require("../assets/img/smart/u6.png")} alt="logo-neoway" />

          <img src={require("../assets/img/neoway-b3.png")} alt="logo-neoway" />
        </div>
      </footer>
    </>
  );
}
