const startUps = [
  {
    src: require("../../assets/img/startups/datarudder.png"),
    alt: "Data rudder",
  },
  {
    src: require("../../assets/img/startups/gofind.png"),
    alt: "GoFind",
  },
  {
    src: require("../../assets/img/startups/perinity.png"),
    alt: "Perinity.com",
  },
  {
    src: require("../../assets/img/startups/vaas.png"),
    alt: "Vaas",
  },
  {
    src: require("../../assets/img/startups/88i-seguradora.png"),
    alt: "88i Seguradora digital",
  },

  {
    src: require("../../assets/img/startups/botz.png"),
    alt: "Botz",
  },
];

/* const logosRemovidas = [
  {
    src: require("../../assets/img/startups/velotax.png"),
    alt: "VeloTax",
  },
  {
    src: require("../../assets/img/startups/minds.png"),
    alt: "Minds Digital",
  },
  {
    src: require("../../assets/img/startups/autoforce.png"),
    alt: "Autoforce",
  },
  {
    src: require("../../assets/img/startups/dealersites.png"),
    alt: "Dealer sites",
  },
    {
    src: require("../../assets/img/startups/horus.png"),
    alt: "Horus",
  },
    {
    src: require("../../assets/img/startups/legendei.png"),
    alt: "Legendei",
  },
    {
    src: require("../../assets/img/startups/kronoos.png"),
    alt: "Kronoos",
  },
]; */

export default function Conheca() {
  return (
    <section id="conheca">
      <img
        className="gp-23"
        alt="ondas"
        src={require("../../assets/img/bg/gp23.png")}
      />

      <div id="future-startups">
        <div className="container">
          <div className="espaçamento-lp-title">
            <span className="subtitle">
              VEJA QUEM SÃO AS STARTUPS QUE JÁ FAZEM PARTE DA SMART
            </span>

            <div>
              <ul className="startups">
                {startUps &&
                  startUps.map((logo, index) => {
                    return (
                      <li key={index} className="startup-card">
                        <img src={logo.src} title={logo.alt} alt={logo.alt} />
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container-screen">
        <div className="espaçamento-lp-title">
          <span className="lp-title">CONHEÇA A SMART</span>
        </div>

        <div>
          <h2 className="title-h2">
            Buscamos negócios escaláveis para serem potencializados e criarem
            novos produtos em parceria com a Neoway e a B3
          </h2>

          <div className="descricao">
            <span>
              Focamos em empresas que possuam tecnologias replicáveis em Data
              Analytics para Mercado de Capitais, Seguro, Cobrança
            </span>
            <span>
              e Crédito, e que busquem na SMART uma fonte de conhecimento e
              crescimento para seu negócio.
            </span>
          </div>
        </div>

        <div className="cards-container">
          <div className="card">
            <div className="logo-wrapper">
              <img
                width="180px"
                height="84px"
                src={require("../../assets/img/neoway/neoway@2x.png")}
                alt="neoway-logo"
                className="first-img"
              />
              <img
                src={require("../../assets/img/neoway/neoway-w.png")}
                alt="neoway-logo"
                className="second-img"
              />
            </div>

            <div className="info">
              <span>
                A maior empresa de Data Analytics e Inteligência Artificial da
                América Latina
              </span>
            </div>
          </div>

          <div className="card">
            <div className="logo-wrapper">
              <img
                src={require("../../assets/img/b3/b3.png")}
                alt="b3-logo"
                className="first-img"
              />
              <img
                src={require("../../assets/img/b3/b3-w.png")}
                alt="b3-logo"
                className="second-img"
              />
            </div>

            <div className="info">
              <span>
                A bolsa do Brasil, uma das maiores provedoras de infraestrutura
                para o mercado financeiro do mundo
              </span>
            </div>
          </div>

          {/* <div className="card">
            <div className="logo-wrapper">
              <img src={require("../../assets/img/ace/ace.png")} alt='ace-logo' className="first-img" />
              <img src={require("../../assets/img/ace/ace-w.png")} alt='ace-logo' className="second-img" />
            </div>

            <div className="info">
              <span>
                Uma empresa especialista em criar negócios e apoiar
                empreendedores
              </span>
            </div>
          </div> */}

          <div className="card">
            <div className="logo-wrapper">
              <img
                width="160px"
                height="71px"
                src={require("../../assets/img/l4/l4@2x.png")}
                alt="l4"
                className="first-img"
              />
              <img
                src={require("../../assets/img/l4/l4-w.png")}
                alt="l4"
                className="second-img"
              />
            </div>

            <div className="info">
              <span>
                O fundo de investimentos que empodera negócios e estimula a
                inovação que desenvolve o Brasil
              </span>
            </div>
          </div>

          <div className="card">
            <div className="logo-wrapper">
              <img
                width="160px"
                height="43px"
                src={require("../../assets/img/neurotech/logo-neurotech-b3@2x.png")}
                alt="l4"
                className="first-img"
              />
              <img
                src={require("../../assets/img/neurotech/logo-neurotech-b3-branca.png")}
                alt="l4"
                className="second-img"
              />
            </div>

            <div className="info">
              <span>
                Empresa pioneira na criação de soluções e sistemas de
                inteligência artificial, machine learning e big data
              </span>
            </div>
          </div>
        </div>

        <div className="diferente">
          <span>VEJA POR QUE SMART É DIFERENTE</span>
          <div className="distancia">
            <img
              src={require("../../assets/img/icons/arrow-down.png")}
              alt="icone-flexa"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
