import React from "react";
import { useNavigate } from "react-router-dom";

import logo from "../assets/img/logo.png";

export default function NavBar({ other }) {
  const navigate = useNavigate()

  function toggle() {
    document
      .getElementById("menu-toggle")
      .classList.toggle("menu-toggle-active");
    document.querySelector("nav").classList.toggle("open");
  }

  let navBarBg = true
  const changeBackground = () => {
    if (window.scrollY > 0 && navBarBg) {
      document.getElementById('nav-bar').classList.add('bg-tr')
      navBarBg = false
    } else if (window.scrollY === 0 && !navBarBg) {
      document.getElementById('nav-bar').classList.remove('bg-tr')
      navBarBg = true
    }
  }

  const goHome = () => {
    if (other) { navigate('/#inicio') }
    window.scrollTo(0, 0);
  }

  React.useEffect(() => {
    window.addEventListener("scroll", changeBackground)
  })

  return (
    <header className="navigation" id="nav-bar">
      <div className="bg-layer-nav" />

      <div className="container">
        <div className="header-content">
          <div
            className="smart-logo"
            onClick={goHome}
          >
            <a
              href="#inicio"
              alt="Smart - Home"
              title="Smart - Home"
            >
              <img src={logo} alt="logo" />
            </a>
          </div>

          <nav>
            <ul className="mobile">
              <div className="layer" />

              <li onClick={() => { toggle(); other && navigate('/#conheca') }}>
                <a href="#conheca">CONHEÇA A SMART</a>
              </li>

              <li onClick={() => { toggle(); other && navigate('/#sobre') }}>
                <a href="#sobre">O QUE É SMART</a>
              </li>

              <li onClick={() => { toggle(); other && navigate('/#ser') }}>
                <a href="#ser">QUEM PODE SER SMART?</a>
              </li>
              
              <li onClick={() => { toggle(); other && navigate('/#metodologia') }}>
                <a href="#metodologia">CONHECA A METODOLOGIA</a>
              </li>
              
              <li onClick={() => { toggle(); other && navigate('/#mentores') }}>
                <a href="#mentores">CONHECA NOSSO MENTORES</a>
              </li>
              
              <li onClick={() => { toggle(); other && navigate('/#tornar') }}>
                <a href="#tornar">COMO SE TORNAR SMART</a>
              </li>
              
              <li onClick={() => { toggle(); other && navigate('/#faq') }}>
                <a href="#faq">FAQ</a>
              </li>
            </ul>

            <ul className="nav-list">
              <li onClick={() => { other && navigate('/#sobre') }} ><a href="#sobre">SOBRE</a></li>
              <li onClick={() => { other && navigate('/#mentores') }} ><a href="#mentores">MENTORES</a></li>
              <li onClick={() => { other && navigate('/#cases') }} ><a href="#cases">CASES</a></li>
              <li onClick={() => { other && navigate('/#faq') }} ><a href="#faq">FAQ</a></li>
            </ul>
          </nav>

          <a
            href="#!"
            className="menu-toggle"
            id="menu-toggle"
            onClick={toggle}
          >
            <span></span>
          </a>
        </div>
      </div>
    </header>
  )
}