import { ButtonG, ButtonS, Carousel, NavBar } from "../../components";
import CardsResultados, {
  Conhecimento,
  SmartTools,
  Mentorias,
  Networking,
  Infraestrutura,
  EquityFree,
} from "../../components/cards/CardsResultados";
import {
  Passo1,
  Passo2,
  Passo3,
  Passo4,
  Passo5,
} from "../../components/cards/CardsTonar";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// import { useNavigate } from "react-router-dom";
import {
  Aceleradora,
  Incubadora,
  Potencializadora,
} from "../../components/table/Tables";
import Conheca from "./Conheca";
import { CardTypes } from "../../components/card-types/CardTypes";
import FAQ from "../../components/FAQ";
import VideoIframe from "../../components/video/VideoIframe";

export default function LandingPage() {
  // const navigate = useNavigate();
  // const textBtns = "Participar do Batch";
  const textBtns = "Seja Smart";

  // function openCardFind(id, sId) {
  //   const cardP = document.getElementById(sId)
  //   const card = document.getElementById(id)
  //   card.classList.toggle('display-none');
  //   cardP.classList.toggle('cards-find-unset')
  // }

  // function toForm() {
  //   navigate("/smart-contato");
  // }

  // const toLp = () => {
  //   window.location.href =
  //     "https://cloud.conteudo.neoway.com.br/lp-smart-contato?utm_source=site&utm_medium=cta&utm_campaign=smart_summit#conversion-form";
  // };

  return (
    <>
      <NavBar />

      <div className="lp-wrapper animeShow">
        <section id="inicio">
          <div className="backdrop" />

          <div className="container-screen">
            <h1>
              <strong>Em breve</strong>, mais informações
              <br /> sobre o próximo batch
            </h1>

            <div className="to-be-smart-i">
              <ButtonG disabled placeholder={textBtns} />
            </div>
          </div>
        </section>

        <CardTypes />

        <Conheca />

        <section id="sobre">
          <img
            className="gp-84"
            alt="ondas"
            src={require("../../assets/img/bg/gp84.png")}
          />

          <div className="container-screen">
            <div className="espaçamento-lp-title">
              <span className="lp-title">O QUE É SMART</span>
            </div>

            <div>
              <h2 className="title-h2">
                Somos uma potencializadora de negócios
              </h2>

              <div className="descricao">
                <span>
                  Usamos toda a expertise de negócios Neoway e B3 para agregar
                  valor a sua empresa e ajuda você a construir <br />
                  os mecanismos para:
                </span>
              </div>
            </div>

            <div className="mecanismos">
              <div className="primeiro">
                <img
                  src={require("../../assets/img/bg/gp31.png")}
                  alt="numero-um"
                  className="numero-web"
                />
                <div className="info">
                  <div className="n-mobile-container">
                    <img
                      src={require("../../assets/img/bg/gp31.png")}
                      alt="numero-um"
                      className="numero-mobile"
                    />
                    <h3 className="title-h3">Conquistar o mercado B2B</h3>
                  </div>
                  <span className="m-descricao">
                    Use a vasta experiência da Neoway e da B3 nos cenários B2B e
                    B2B2C a seu favor. Acelere seu processo de amadurecimento e
                    crescimento de receitas.
                  </span>
                </div>
              </div>

              <div className="segundo">
                <img
                  src={require("../../assets/img/bg/gp32.png")}
                  alt="numero-dois"
                  className="numero-web"
                />
                <div className="info">
                  <div className="n-mobile-container">
                    <img
                      src={require("../../assets/img/bg/gp32.png")}
                      alt="numero-dois"
                      className="numero-mobile"
                    />
                    <h3 className="title-h3">Multiplicar seus negócios</h3>
                  </div>
                  <span className="m-descricao">
                    Tenha a oportunidade de ofertar soluções, juntamente com
                    Neoway e B3, para uma base de mais de 800 clientes de
                    diferentes segmentos.
                  </span>
                </div>
              </div>
            </div>

            <div className="compare">
              <h2 className="title-h2">
                Compare e veja porque a Smart é a escolha certa para
                potencializar sua startup
              </h2>

              <div className="table">
                <img
                  className="gm-23"
                  alt="ondas"
                  src={require("../../assets/img/bg/gm23.png")}
                />

                <Incubadora className="table-web" />
                <Potencializadora className="table-web" />
                <Aceleradora className="table-web" />

                <div className="table-mobile">
                  <Swiper
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    className="mySwiper"
                    scrollbar={{ draggable: true }}
                  >
                    <SwiperSlide>
                      <Incubadora className="table-mobile" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Potencializadora className="table-mobile" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Aceleradora className="table-mobile" />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>

          <div className="background-blue-energy">
            <img
              src={require("../../assets/img/bg/blue-light-bg.png")}
              className="bright-blue-bg"
              alt="Bright Light Background"
            />

            {/* <div id="ser" className="quem-pode-ser">
              <div className="container-screen">
                <div className="espaçamento-lp-title">
                  <span className="lp-title">
                    QUEM PODE SER SMART?
                  </span>
                </div>

                <div>
                  <h2 className="title-h2">Veja quais tipos de startups estamos em busca</h2>
                  <div className="descricao">
                    <span>
                      Descubra se você é uma delas
                    </span>
                  </div>

                  <div className="card-container-find">
                    <div className="bg-filter" />
                    
                    <div className="card-find blue" id='sCr-f' onClick={() => openCardFind('card-find-f', 'sCr-f')}>
                      <div className="card-content">
                        <span className="title">
                          Fraude
                        </span>

                        <svg xmlns="http://www.w3.org/2000/svg" width="17.53" height="16" viewBox="0 0 17.53 16">
                          <path d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z" transform="translate(129.595 16) rotate(180)" fill="#fff" />
                          <path d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z" transform="translate(129.595 16) rotate(180)" fill="#fff" />
                        </svg>

                        <div id='card-find-f' className="card-description">
                          <div>
                            <span>Buscamos startups que desenvolvem soluções para:</span>
                          </div>

                          <ul>
                            <li><span className="dot">• </span><strong>Fraude Pix: </strong>identificar possíveis fraudes na utilização de pagamentos via Pix</li>
                            <li><span className="dot">• </span><strong>Fingerprint Digital: </strong>fraudes em máquinas digitais e dados em relação a padrões de fraude no mundo digital</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="card-find pink" id='sCr-rc' onClick={() => openCardFind('card-find-rc', 'sCr-rc')}>
                      <div className="card-content">
                        <span className="title">
                        Mercado de capitais
                        </span>

                        <svg xmlns="http://www.w3.org/2000/svg" width="17.53" height="16" viewBox="0 0 17.53 16">
                          <path d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z" transform="translate(129.595 16) rotate(180)" fill="#fff" />
                          <path d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z" transform="translate(129.595 16) rotate(180)" fill="#fff" />
                        </svg>

                        <div id='card-find-rc' className="card-description">
                          <div>
                            <span>Buscamos negócios que desenvolvem soluções para:</span>
                          </div>

                          <ul>
                            <li><span className="dot">• </span><strong>Análise de sentimentos: </strong>monitoramento de publicações, discussões e tendências do mercado de capitais em redes sociais e mídias digitais.</li>
                            <li><span className="dot">• </span><strong>Análise de comportamentos: </strong>solução de NPL para apoiar tomadas de decisão de operadores financeiros.</li>
                            <li><span className="dot">• </span><strong>Ganhos operacionais para operadores financeiros: </strong>tecnologia para criação e gestão de carteiras de investimento personalizadas.</li>
                            <li><span className="dot">• </span><strong>ESG: </strong>captação de dados sobre iniciativas ambientais, sociais e de governança, de empresas públicas e privadas para análise e
definição de Score ESG.</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="card-find orange" id='sCr-sm' onClick={() => openCardFind('card-find-sm', 'sCr-sm')}>
                      <div className="card-content">
                        <span className="title">Seguro, Cobrança e Crédito</span>

                        <svg xmlns="http://www.w3.org/2000/svg" width="17.53" height="16" viewBox="0 0 17.53 16">
                          <path d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z" transform="translate(129.595 16) rotate(180)" fill="#fff" />
                          <path d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z" transform="translate(129.595 16) rotate(180)" fill="#fff" />
                        </svg>

                        <div id='card-find-sm' className="card-description">
                          <div>
                            <span>Buscamos negócios que desenvolvem soluções para:</span>
                          </div>

                          <ul>
                            <li><span className="dot">• </span><strong>Dados georreferenciados:</strong> tecnologias que viabilizam a segmentação de perfis comportamentais, sociodemográficos e econômicos.</li>
                            <li><span className="dot">• </span><strong>Dados sintéticos:</strong> tecnologias que possam ser replicadas para utilização nos
segmentos de seguro, cobrança e crédito</li>
                            <li><span className="dot">• </span><strong>Machine learning e Deep learning:</strong> tecnologias que possam ser replicadas para utilização nos
segmentos de seguro, cobrança e crédito.</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="card-find darkblue" id='sCr-a' onClick={() => openCardFind('card-find-a', 'sCr-a')}>
                      <div className="card-content">
                        <span className="title">
                          Automotivo
                        </span>

                        <svg xmlns="http://www.w3.org/2000/svg" width="17.53" height="16" viewBox="0 0 17.53 16">
                          <path d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z" transform="translate(129.595 16) rotate(180)" fill="#fff" />
                          <path d="M128.071,16a.337.337,0,0,1-.311-.209L122.395,2.728a1.694,1.694,0,0,0-3.126-.007L113.9,15.791a.337.337,0,0,1-.311.209h-1.356a.169.169,0,0,1-.156-.233l5.641-13.706a3.378,3.378,0,0,1,6.225.007l5.638,13.7a.168.168,0,0,1-.156.233Z" transform="translate(129.595 16) rotate(180)" fill="#fff" />
                        </svg>

                        <div id='card-find-a' className="card-description">
                          <div><span>Buscamos startups que desenvolvem soluções para:</span></div>

                          <ul>
                            <li><span className="dot">• </span><strong>Dados do ecossistema de veículos: </strong>Leilão, Recall, etc.</li>
                            <li><span className="dot">• </span><strong>Análise de imagem das condições e características dos veículos</strong></li>
                            <li><span className="dot">• </span><strong>Transformação digital da jornada de comercialização de veículos</strong></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div id="metodologia" className="conheca-metodologia-smart">
              <div className="container">
                <div className="espaçamento-lp-title">
                  <span className="lp-title">CONHEÇA A METODOLOGIA SMART</span>
                </div>

                <h2 className="title-h2">
                  Como geramos resultados rápidos para o seu negócio
                </h2>

                <div className="descricao">
                  <span>
                    Oferecemos recursos e inteligência para ajudar o seu negócio
                    a escalar crescimento
                  </span>
                </div>

                <div className="como-gerar-resultado cgr-web">
                  <SmartTools />
                  <Conhecimento />
                  <Mentorias />
                  <Networking />
                  <Infraestrutura />
                  <EquityFree />
                </div>

                <div className="como-gerar-resultado cgr-mobile">
                  <CardsResultados />
                </div>
              </div>
            </div>
          </div>

          <div className="faca-negocios">
            <img
              src={require("../../assets/img/bg/gp49.png")}
              alt="ondas"
              className="ondas"
            />

            <div className="left">
              <div className="title">
                <span>Faça negócios com grandes empresas do mercado!</span>
              </div>

              <div>
                <span className="fn-descricao">
                  Oferecemos recursos e inteligência para ajudar o seu negócio a
                  escalar crescimento
                </span>
              </div>

              <div className="fn-b-c fnbc-web">
                <ButtonS disabled placeholder={textBtns} />
              </div>
            </div>

            <div className="right">
              <div>
                <h3 className="fn-number">+800</h3>
                {/* <img src={require("../../assets/img/bg/c156.png")} alt="700" /> */}
              </div>
              <div>
                <span className="fn-descricao">
                  Empresas do mercado B2B para você acessar
                </span>
              </div>
            </div>

            <div className="fnbc-mobile">
              <ButtonS disabled placeholder={textBtns} />
            </div>
          </div>
        </section>

        <section id="mentores">
          <div className="container">
            <div className="espaçamento-lp-title">
              <span className="lp-title">CONHEÇA NOSSOS MENTORES</span>
            </div>

            <div>
              <h2 className="title-h2">
                Receba mentoria dos maiores especialistas em negócios
              </h2>
              <div className="descricao">
                <span>
                  As mentes por trás do sucesso da Neoway e B3 vão ajudar você a
                  aplicar modelos de liderança ágil e inovadora.
                </span>
              </div>
            </div>
            <div className="container-car">
              <Carousel />
            </div>
          </div>
        </section>

        <section id="cases">
          <div className="cases-bg-lines">
            <img
              src={require("../../assets/img/bg/a3.png")}
              alt="ondas"
              className="ct-bg"
            />

            <div className="container">
              <div className="video-container">
                <div className="v-text">
                  {/* <span className="v-title">Veja os depoimentos de startups aprovadas no <strong>Batch#1</strong></span> */}
                  <span className="v-title">
                    Veja os depoimentos de quem{" "}
                    <strong>já faz parte da Smart!</strong>
                  </span>

                  {/* <div className="v-descricao">
                    <span>As startups Data Rudder e Vaas contam como está sendo a experiência com a Smart potencializadora. Confira!</span>
                  </div> */}
                </div>
                <div className="video">
                  {/* <iframe width="500" height="284" src="https://www.youtube.com/embed/czimDqhQX0I" title="video-neoway"></iframe> */}
                  <VideoIframe src="https://www.youtube.com/embed/czimDqhQX0I" />
                </div>
              </div>
            </div>

            <div className="container">
              <div className="como-se-tornar">
                <div className="espaçamento-lp-title">
                  <span className="lp-title">Como se tornar Smart</span>
                </div>

                <div>
                  <h2 className="title-h2">
                    Quero ser Smart! O que devo fazer?
                  </h2>
                </div>

                <div id="tornar" className="card-tonar-container">
                  <Passo1 className="passos-web" />
                  <Passo2 className="passos-web" />
                  <Passo3 className="passos-web" />
                  <Passo4 className="passos-web" />
                  <Passo5 className="passos-web" />

                  <div className="passos-mobile">
                    <Swiper
                      pagination={{ clickable: true }}
                      modules={[Pagination]}
                      className="mySwiper"
                      scrollbar={{ draggable: true }}
                    >
                      <SwiperSlide>
                        <Passo1 />
                      </SwiperSlide>
                      <SwiperSlide>
                        <Passo2 />
                      </SwiperSlide>
                      <SwiperSlide>
                        <Passo3 />
                      </SwiperSlide>
                      <SwiperSlide>
                        <Passo4 />
                      </SwiperSlide>
                      <SwiperSlide>
                        <Passo5 />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="momento" className="momento">
          <div className="left-container">
            <div className="left">
              <div className="title">
                <span>
                  Este é o seu momento! <br /> <strong>Seja Smart</strong>
                </span>
              </div>

              <div>
                <span className="m-descricao">
                  Chegou a hora de fazer a grande escolha que vai mudar para
                  sempre o rumo do seu negócio!
                </span>
              </div>

              <div className="fn-b-c fnbc-l-web">
                <ButtonS disabled placeholder={textBtns} />
              </div>
            </div>
          </div>

          <div className="m-bg">
            <img
              src={require("../../assets/img/bg/abstract-neon.png")}
              alt="background"
            />
            <div className="fn-b-c fnbc-b-mobile">
              <ButtonS disabled placeholder={textBtns} />
            </div>
          </div>
        </section>

        <section id="faq">
          <div className="container">
            <div className="espaçamento-lp-title">
              <span className="lp-title">FAQ</span>
            </div>

            <div>
              <h2 className="title-h2">
                Veja respostas para dúvidas frequentes
              </h2>
            </div>

            <FAQ />
          </div>
        </section>

        <footer className="footer">
          <div className="container-footer">
            <img
              src={require("../../assets/img/smart/u6.png")}
              alt="logo-neoway"
            />

            <img
              width="500px"
              height="auto"
              src={require("../../assets/img/logo-neoway-b3-l4-neurotech-b3.png")}
              alt="logo-neoway"
            />
          </div>
        </footer>
      </div>
    </>
  );
}
